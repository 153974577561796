.containerStore{
    background: #9B5AE6;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Optional: adjust the height as needed */
}

.imageContainer{
    width: 100%;
    align-items: center;
    text-align: center;
    margin-top: 0px;
    padding-top: 0px;
}

.profileImage{
    height: 150px;
    border-radius: 50%;
}

.centerContainer{
    width: 80%;
    text-align: center;
    margin-top: 5px;
}
.descriptionContainer{
    color: white;
    font-weight: bold;
    text-decoration: none;
}

.imageContainerStore{
    margin-top: 20px;
}
.storeImage{
    width: 150px;
}

.linkToHome{
    color: white;
    text-decoration: none;
    font-weight: bold;
}
