.fullPageBackgroundNutrition {
    height: calc(100vh - 80px);
    width: 100%;
    position: relative;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent dark overlay */
    min-height: 700px;
}

.fullPageBackgroundNutrition video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 0;
    opacity: 0.3; /* Adjust the overlay opacity */ 
    background-size: cover; /* Cover the entire container */
    background-position-x: center;
    opacity: 0.2; /* Adjust the overlay opacity */
}

.mainRightBodyNutrition{
    text-align: left;
}

@media (max-width: 950px) {
    .mainRightBodyNutrition{
        text-align: center;
    }

    .fullPageBackgroundNutrition {
        height: 900px;
        width: 100%;
        position: relative;
        background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent dark overlay */
        
    }
    .fullPageBackgroundNutrition video {
        width: 100%;
        height: 900px;
        position: absolute;
        object-fit: cover;
        z-index: 0;
        opacity: 0.3; /* Adjust the overlay opacity */
        
      }
      
}

