.mainSection{
    user-select: none;
    padding: 0;
    margin: 0;
}
.header{
    display: flex;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    background-color: #1A0C3E;
    height: 80px;
    width: 100%;
}
.fullPageBackground {
    position: relative;
    width: 100%; /* 100% of viewport width */
    height: calc(100vh - 80px); /* 100% of viewport height */
    overflow: hidden; /* Prevent image overflow */

    min-height: 700px;
}



.fullPageBackground::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/valky-app-prod.appspot.com/o/emails%2FfullbodyIntro.jpg?alt=media&token=fae6e489-64b3-4848-9c7a-6e69bbfe8313'); /* Set the path to your SVG image */
    background-size: cover; /* Cover the entire container */
    opacity: 0.2; /* Adjust the overlay opacity */
    min-height: 700px;
}

.logo{
    height: 50px;
    margin-left: 20px;
}
.headerTitle{
    margin-right: 20px;
    color: white;
}
 
.mainLeftBody{
    position: absolute;
    width: 40vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; 
}
.imageGif{
    height: 80vh;
    min-height: 600px;
    vertical-align: middle;
    border-radius: 60px;
    max-width: 90vw;
}
.mainRightBody{
    position: absolute;
    width: calc(60vw - 40px);
    margin-left: calc(40% + 20px);
    height: 100%;
    color: white;
    font-size: 30px;
    align-items: center;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.storeLinks{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.divider{
    width: 50px;
}
.mainLeftBodyMobile{
    display: none;
}
@media (max-width: 950px) {
    .headerTitle{
        display: none;
    }
    .fullPageBackground {
        position: relative;
        width: 100vw; /* 100% of viewport width */
        height: 1000px; /* 100% of viewport height */
        overflow: hidden; /* Prevent image overflow */
        background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent dark overlay */
    }
    .fullPageBackground::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../../images/mainImage.svg'); /* Set the path to your SVG image */
        background-size: cover; /* Cover the entire container */
        background-position-x: center;
        opacity: 0.2; /* Adjust the overlay opacity */
    }
    .mainLeftBody{
        display: none;
    }
    .mainRightBody{
        font-size: 20px;
        position: absolute;
        width: calc(100vw - 20px);
        margin-left: calc(10px);
        height: fit-content;
        color: white;
        text-align: center;
        display: block;
    }
    .mainLeftBodyMobile{
        display: block;
    }
    .imageGif{
        height: 500px;
        vertical-align: middle;
        border-radius: 45px;
    }
}
