.infoSection{
    min-height: calc(100vh + 0px);
    margin: 0;
    padding: 0;
    user-select: none;
    height: calc(100vh - 0px); /* 100% of viewport height */
    overflow: hidden; /* Prevent image overflow */
    background-color: rgba(255, 255, 255, 1); /* Semi-transparent dark overlay */
    display: flex;
    font-size: 30px;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 700px;
}

.infoLeftSection{
    position: absolute;
    width: 40vw;
    height: calc(100vh);
    min-height: 700px;
    margin-left: 40px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.infoLeftSection p{
    margin: 10px 0;
}
.infoLeftSection h1{
    margin: -10px 0;
}
.infoLeftSection h3{
    margin: 20px 0 0 0px 20px;
}
.infoRightSection{
    width: calc(60vw);
    margin-left: calc(40% + 40px);
    height: 100%;
    color: black;
    font-size: 30px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.infoLeftRow1{
    position: relative;
    display: flex;
    color: #9B5AE6;
    font-size: 14px;
}
.infoLeftIconContainer{
    width: 12vw;
    min-width: 12vw;
    margin-left: 10px;
}

.infoIcon{
    width: 50px;
    height: 50px;
    font-weight: 600;
}
.rightMobiletitle{
    display: none;
}
.rightMobileSubtitle{
    display: none;
}
.dividerInfo{
    height: 40px;
}
@media (max-width: 950px) {
    .dividerInfo{
        height: 10px;
    }
    .rightMobileSubtitle{
        display: block;
        font-size: 18px;
        width: 90vw;
        margin-left: -0px;
    }
    .rightMobiletitle{
        display: block;
        margin-left: -0px;
        margin-top: -0px;
        width: 90vw;
    }
    .infoRighSubtitle{
        width: 100vw;
        text-align: left;
        margin: 20px;
        margin-left: 20vw;
        font-size: 18px;
    }
    .infoSection{
        height: 1100px;
    }
    .infoSection{
        display: block;
    }
    .infoLeftSection{
        display: none;
    }
    .infoRightSection{
        margin-left: -20px;
        width: 100vw;
    }
    .infoLeftRow1{
        font-size: 16px;
    }
    .infoLeftIconContainer{
        width: 30vw;
        min-width: 30vw;
        margin-left: 10px;
    }
    .infoIcon{
        width: 60px;
        font-weight: 600;
    }
}




