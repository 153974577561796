.App {
    width: 100vw;
    height: 100vh;
    display: flex;
}

.mainImage{
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
    height: 80vh;
}

.leftContainer{
    position: absolute;
    background-color: #1A0C3E;
    width: 30%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 100%;
}

.rightContainer{
    width: 70%;
    margin-left: 0%;
}
.topRightContainer{
    position: absolute;
    background-color: #00FFE0;
    width: 70%;
    height: 50vh;
    top: 0;
    left: 30%;
}
.bottomRightContainer{
    position: absolute;
    background-color: white;
    width: 70%;
    height: 50vh;
    top: 50vh;
    left: 30%;
}

.maintitle{
    position: absolute;
    top: calc(5vh);
    width: 100%;
    text-align: center;
    font-size: 50px;
    color: #40306A;
    font-weight: 600;
}

.subtitle{
    position: absolute;
    color: #40306A;
    font-weight: 400;
    max-width: 600px;
    text-align: center;
    font-size: 25px;
    top: calc(14vh);
    margin-left: calc(50% - 300px);
}

.modalFormContainer{
    position: absolute;
    top: calc(50vh - 200px);
    left: calc(65% - 250px);
    width: 500px;
    height: 400px;
}

.modalForm{
    padding-top: 30px;
    height: calc(90% - 20px);
    width: 90%;
    margin-left: 5%;
    background-color: #40306A;
    border-radius: 10px;
}

.advice{
    color: #40306A;
    margin-top: 20px;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
}

.inputField{
    width: 80%;
    margin-left: calc(10% - 10px);
    margin-top: 20px;
    height: 30px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.32);
    border: 1px solid #40306A;
    border: 3px solid #40306A;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    padding: 0px 10px 0 10px;
    color: white;
    font-weight: 600;
}

.inputField:focus {
    border: 3px solid white;
}

.registerButton{
    width: 150px;
    background-color: white;
    padding: 10px;
    margin-left: calc(50% - 75px);
    text-align: center;
    margin-top: 30px;
    border-radius: 10px;
    color: #40306A;
    font-weight: 600;
    background-color: #00FFE0;
    cursor: pointer;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(214, 213, 213);
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
}

.cellphone{
    display: flex;
    width: 210px;
    margin-left: calc(50% - 100px);
    margin-top: 20px;
}

.divider{
    width: 5%;
}

.cell{
    background-color: white;
    padding: 3px 0 3px 0;
    border-radius: 10px;
    width: 50%;
    text-align: center;
    color: #40306A;
    font-weight: 600;
    cursor: pointer;
}

.cellHover{
    background-color: #00FFE0;
    padding: 3px 0 3px 0;
    border-radius: 10px;
    width: 50%;
    text-align: center;
    color: #40306A;
    font-weight: 600;
    cursor: pointer;
}

.bottomPhone{
    display: none;
}

.topPhone{
    display: none;
}

.logoHeader{
    position: absolute;
    z-index: 10;
    color: white;
    width: 200px;
    padding: 20px;
}
.topRightContainerMobile{
    display: none;
}

.politicas{
    font-size: 12px;
    color: rgb(180, 178, 178);
    text-decoration: underline;
    width: 100%;
    cursor: pointer;
}
.politicasContainer{
    color: #40306A;
    margin-top: 10px;
    text-align: center;
}

.modalFormContainerRegistro{
    position: relative;
    margin-top: 0;
    left: 10%;
    width: 80%;
    height: fit-content;
    max-height: 300px;
    min-height: 300px;
    z-index: 10;
    margin-bottom: 40px;
}
.modalFormRegistro{
    padding-top: 30px;
    height: calc(90% - 20px);
    width: 100%;
    margin-left: 0%;
    background-color: #40306A;
    border-radius: 10px;
    padding-right: 5%;
    margin-top: -100px;
    padding-bottom: 70px;
}

.subtitleHomeContainerRegistro{
    width: 100%;
    font-weight: 600;
    text-align: center;
    color: #40306A;
    background-color: #00FFE0;
    height: fit-content;
    padding: 30px 0 30px 0;
    margin-top: 0px;
    z-index: 2;
}
.cellTypeContainer{
    display: flex;
    text-align: center;
    align-items: center;
    width: 100%;
    margin-left: 2.5%;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 30px;
}
.cellType{
    display: inline-block;
    text-align: center;
    background-color: white;
    margin-right: 10px;
    padding: 5px;
    border-radius: 8px;
    width: 80px;
    cursor: pointer;
}

@media (max-width: 650px) {
    .App {
        width: 100%;
    }
    .topRightContainer{
        display: none;
    }
    .mainImage{
        position: absolute;
        left: calc(40% - 10px);
        top: calc(50% - 200px);
        bottom: 0;
        z-index: 3;
        width: 60%;
        height: auto;
    }
    .leftContainer{
        position: absolute;
        background-color: #00FFE0;
        top: 0;
        width: 100%;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        z-index: 2;
        height: 100%;
        min-height: 100vh;
    }
    .modalFormContainer{
        position: absolute;
        top: calc(100% - 300px);
        left: 10%;
        width: 80%;
        height: 80vw;
        max-height: 300px;
        min-height: 300px;
        z-index: 10;
    }
    .inputField{
        margin-top: 10px;
    }
  
    .modalForm{
        padding-top: 10px;
    }
    .registerButton{
        margin-top: 15px;
        height: 20px;
        padding: 5px;
    }
    .bottomPhone{
        position: absolute;
        display: block;
        height: 100px;
        background-color: white;
        width: 100%;
        z-index: 4;
        bottom: -100px;
        height: 250px;
    }
    .topPhone{
        position: absolute;
        display: block;
        background-color: #1A0C3E;
        width: 100%;
        z-index: 4;
        top: 0;
        height: 90px;
        opacity: 0.9;
    }
    .logoHeader{
        position: absolute;
        z-index: 10;
        color: white;
        width: 150px;
        padding-top: 20px;
    }
    .maintitle{
        position: absolute;
        display: block;
        top: 200px;
        width: 100%;
        text-align: center;
        font-size: 50px;
        color: #40306A;
        font-weight: 600;
        z-index: 20;
    }
    .topRightContainerMobile{
        display: block;
        position: absolute;
        z-index: 20;
        top: 18%;
        font-size: 30px;
        width: 240px;
        left: 30px;
        color: #1A0C3E;
        font-weight: 600;
    }
    .modalFormContainerRegistro{
        position: relative;
        margin-top: 0;
        left: 10%;
        width: 80vw;
        height: 80vw;
        max-height: 300px;
        min-height: 300px;
        z-index: 10;
    }
    .modalFormRegistro{
        padding-top: 30px;
        height: calc(90% - 20px);
        width: 90%;
        margin-left: 3%;
        background-color: #40306A;
        border-radius: 10px;
        padding-right: 5%;
        margin-top: -150px;
    }
    
    .subtitleHomeContainerRegistro{
        width: 100%;
        font-weight: 600;
        text-align: center;
        color: #40306A;
        background-color: #00FFE0;
        height: fit-content;
        padding: 30px 0 30px 0;
        margin-top: -10px;
        z-index: 2;
    }
    .cellTypeContainer{
        display: flex;
    }
}