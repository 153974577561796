.fullPageBackgroundReview{
    height: 100vh;
    width: 100%;
    position: relative;
    background-color: #1A0C3E;
    text-align: center;
    align-items: center;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    min-height: 800px;
    

}
.reviewTitle{
    width: 50vw;
    text-align: center;
    padding-top: 5vh;
    font-size: 20px;
    color: white;
}

.commentContainer{
    background-color: white;
    width: 300px;
    border-radius: 20px;
    font-size: 20px;
    align-items: center;
    
}

.profileContainer{
    display: flex;
    padding: 0;
}
.profileName{
    height: fit-content;
    font-weight: 600;
}
.profileImage{
    border: 1px solid rgb(192, 192, 192);
    width: 100px;
    height: 100px;
    margin-top: 20px;
    margin-left: 5%;
    background-color: rgb(192, 192, 192);
    margin-right: 20px;
}
.commentDescription{
    padding: 0px 20px 5px 20px;
    text-align: justify;
}
.crouselContainer{
    background-color: white;
    width: 50vw;
}
.carouselContainer{
    position: relative;
    width: 50vw;
    height: auto;
    padding: 20px;
    color: black;
    font-size: 20px;

    font-size: 20px;
}

.spacerCarousel{
    height: 20px;
}
.slide{
    padding-left: 20px;
    padding-right: 20px;
}
.innerSlide{
    background-color: #ffffff;
    border-radius: 10px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
}

.slideProfile{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    align-items: center;
}
.profileCommnet{
    max-width: 100px;
    max-height: 100px;
    margin-right: 20px;
}

.staticSlide{
    width: calc(50vw - 80px);
    margin-bottom: 20px;
    font-size: 20px;
}
.staticInnerSlide{
    background-color: white;
    border-radius: 10px;
    padding: 5px 20px 10px 20px;
}

@media (max-width: 950px) {
    .fullPageBackgroundReview{
        height: 1000px;
    }
    .reviewTitle{
        width: calc(100vw - 20px);
        text-align: center;
        padding-top: 20px;
        font-size: 16px;
        color: white;
        margin-left: 20px;
    }
    .staticSlide{
        width: calc(80vw);
        margin-bottom: 10px;
        margin-top: 40px;
    }
    .carouselContainer{
        position: relative;
        width: calc(80vw + 80px);
        height: auto;
        padding: 0px;
        color: black;
        font-size: 20px;
        margin-left: 0px;
        margin-top: 20px;
    }

    

}

